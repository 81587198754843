<template>
  <div class="active-imei-config">
    <Loader v-if="loaderEnabled" :logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <div v-if="checkPermission('IMEI_ACTIVE_REPORT_VIEW')" class="ml-3">
            <b-dropdown
              size="sm"
              right
              split
              variant="primary"
              @click="onClickCreate('info-active-imei-config')"
              class="ml-4"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span> <i class="flaticon2-add-1"></i>Tạo mới</span>
                </div>
              </template>
              <b-dropdown-item
                @click="onClickCreate('import-active-imei-config')"
              >
                <i class="far fa-file-excel mr-2"></i>
                <span>Thêm từ excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row>
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập tên cấu hình"
              v-model="apiParams.name"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.dateFrom"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.dateTo"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="apiParams.isActive"
              :options="listStatus"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Trạng thái --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5 mt-4">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          :busy="onLoading"
          bordered
          hover
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(statusName)="row">
            <span
              v-text="row.item.statusName"
              class="label font-weight-bold label-lg label-inline"
              :class="
                row.item.status === 1
                  ? 'label-light-success'
                  : 'label-light-danger'
              "
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(createdAt)="row">
            <p class="text-center">{{ row.item.createdAt }}</p>
            <p class="text-center">{{ row.item.createdByName }}</p>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('IMEI_ACTIVE_REPORT_VIEW')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-show="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store//modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import timeUtils from '@/utils/date';
import moment from 'moment';

export default {
  data() {
    return {
      title: '',
      apiParams: {
        name: '',
        dateFrom: '',
        dateTo: '',
        isActive: null,
      },
      listItem: [],
      onLoading: false,
      numberOfPage: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên',
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '' },
      ],
      dpConfigs: timeUtils.DP_CONFIG,
      listStatus: [
        {
          id: null,
          name: 'Chọn trạng thái',
        },
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 0,
          name: 'Đã khóa',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.title = 'Danh sách cấu hình kích hoạt';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.fetchData();
  },
  methods: {
    editItem: function (item) {
      this.$router.push({
        name: 'info-active-imei-config',
        query: { id: item.id },
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-active-imei-config',
      });
      this.fetchData();
    },
    getParamFilters() {
      const fromDate = this.apiParams.dateFrom
        ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD 00:00:00')
        : null;
      const toDate = this.apiParams.dateTo
        ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
        : null;
      return {
        page: this.$route.query.page || 1,
        limit: 10,
        name: this.apiParams.name ? this.apiParams.name.trim() : null,
        fromDate: fromDate,
        toDate: toDate,
        isActive: this.apiParams.isActive,
      };
    },
    fetchData: async function () {
      this.page = this.$route.query.page || 1;

      const params = this.getParamFilters();

      ApiService.query('v2/active-configs', { params }).then((response) => {
        const responseData = response.data.data;
        this.totalItems = responseData.totalRow;
        this.numberOfPage = responseData.totalPage;
        this.listItem = responseData.dataset.map((item, index) => {
          const count =
            this.page >= 2 ? index + 1 + this.page * 10 - 10 : index + 1;
          return {
            ...item,
            count,
          };
        });
        this.onLoading = false;
      }).catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });;
    },
    deleteItem: async function (item) {
      ApiService.delete('v2/active-configs' + '/' + item.id).then((data) => {
        const { status, message } = data.data;
        if (status === 200) {
          makeToastSuccess(message);
          this.fetchData();
        } else {
          makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa cấu hình kích hoạt!',
        text: 'Bạn có chắc muốn xóa cấu hình kích hoạt này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onClickCreate(namePath) {
      this.$router.push({
        name: `${namePath}`,
      });
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.active-imei-config {
  .icon:hover {
    background-color: #90c6fc;
  }
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 21em;
  }
}
</style>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
